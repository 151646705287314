<template>
  <div class="question_container">
    <div class="question_answers">
      <consumption-habits-questions></consumption-habits-questions>
    </div>
    <div class="question_info">
      <question-info :description="questionDescript" ></question-info>
    </div>
  </div>
  <navigation></navigation>
</template>

<script>
import Navigation from "@/components/Navigation";
import ConsumptionHabitsQuestions from "@/components/questions/ConsumptionHabitsQuestions";
import QuestionInfo from "@/components/QuestionInfo";

export default {
  name: "ConsumptionHabits",
  components: {ConsumptionHabitsQuestions, Navigation, QuestionInfo},
  setup() {
    const questionDescript = "Hvis du vil markedsføre og sælge til din Buyer Persona, skal du forstå, hvordan og " +
        "hvor de skaffer information. I et forsøg på at møde dem, hvor de er, kan du bruge disse indsigter til at " +
        "bestemme, hvor din virksomhed skal have en aktiv tilstedeværelse."

    return{
      questionDescript
    }
  }
}
</script>

<style scoped>

</style>